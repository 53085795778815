.logoContainer {
  margin-right: 16px;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
}

.logoContainer.img {
  max-width: '100%';
}


.googleLogo {
  width: 24px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 2px;
}

.facebookLogo {
  width: 24px;
}

.socialMediaButtonText {
  flex: 1;
  color: #FFF;
}

.divider {
  margin: 16px 0;
  color: #b4b0ba;
  display: flex;
}

.divider hr {
  border: 1px solid #DFE1DF;
  width: 100%;
  border-left: 0;
  border-right: 0;
}

.divider span {
  color: #B4B0BA;
  font-size: 12px;
  margin: 0 6px;
}
