.panel {
  padding: 1px 16px 40px;
}
@media (min-width: 599px) {
  .panel {
    padding: 1px 40px 40px;
  }
}

.description {
  margin-bottom: 46px;
  font-size: 14px;
  color: #333147;
}

.formButton {
  margin: 32px 0 0;
}

@media (min-width: 599px) {
  .formButton {
    margin: 32px 0 0;
  }
}
.formButton.mb0 {
  margin-bottom: 0;
}

.title {
  margin: 36px 0 26px;
  text-align: left;
  padding: 1px 0;
}
@media (min-width: 599px) {
  .title {
    margin: 36px 0 32px;
  }
}
.title.withLargeBottomMargin {
  margin-bottom: 28px;
}

.providerArea {
  margin-bottom: 21px;
  display: flex;
  align-items: center;
}

.providerArea img {
  margin-right: 8px;
}

.providerArea img.fcb {
  width: 24px;
  height: 24px;
}

.providerArea .name {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #333147;
  vertical-align: middle;
}

.verifyMessage {
  margin-bottom: 24px;
}

.nameFieldWidth {
  padding: 8px 0 0;
}
@media (min-width: 599px) {
  .nameFieldWidth {
    width: 232px;
  }
}

@media (max-width: 598px) {
  .responsiveGrid {
    display: block !important;
  }
}

.fieldMB {
  margin-bottom: 16px;
}
@media (min-width: 599px) {
  .fieldMB {
    margin-bottom: 24px;
  }
}
