@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Blk.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_BlkIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_LtIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_MdIt.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Rg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_Th.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Aleck Sans';
  src: url('./common/assets/fonts/ATTAleckSans_W_ThIt.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-black';
  src: url('./common/assets/fonts/montserrat-black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-blackitalic';
  src: url('./common/assets/fonts/montserrat-blackitalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'montserrat-bold';
  src: url('./common/assets/fonts/montserrat-bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-bolditalic';
  src: url('./common/assets/fonts/montserrat-bolditalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-extrabold';
  src: url('./common/assets/fonts/montserrat-extrabold.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-extralight';
  src: url('./common/assets/fonts/montserrat-extralight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-extralightitalic';
  src: url('./common/assets/fonts/montserrat-extralightitalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-light';
  src: url('./common/assets/fonts/montserrat-light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-medium';
  src: url('./common/assets/fonts/montserrat-medium.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-mediumitalic';
  src: url('./common/assets/fonts/montserrat-mediumitalic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-regular';
  src: url('./common/assets/fonts/montserrat-regular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-semibold';
  src: url('./common/assets/fonts/montserrat-semibold.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'montserrat-semibolditalic';
  src: url('./common/assets/fonts/montserrat-semibolditalic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-thin';
  src: url('./common/assets/fonts/montserrat-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-thinitalic';
  src: url('./common/assets/fonts/montserrat-thinitalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'gibson-extralight';
  src: url('https://vsynplus.com/branding-assets/fonts/copy/thin/gibson-thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'gibson-light';
  src: url('https://vsynplus.com/branding-assets/fonts/copy/light/gibson-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'gibson-regular';
  src: url('https://vsynplus.com/branding-assets/fonts/copy/regular/gibson-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'gibson-medium';
  src: url('https://vsynplus.com/branding-assets/fonts/copy/medium/gibson-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gibson-bold';
  src: url('https://vsynplus.com/branding-assets/fonts/copy/semibold/gibson-semibold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  background-clip: text;
}