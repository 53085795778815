.panel {
  padding: 1px 16px 40px;
}
@media (min-width: 599px) {
  .panel {
    padding: 1px 40px 40px;
  }
}

.logo {
  height: 30px;
}

.title {
  margin: 32px 0 32px;
  text-align: left;
  padding: 1px 0;
}
@media (min-width: 599px) {
  .title {
    margin: 36px 0 32px;
  }
}
.title.withLargeBottomMargin {
  margin-bottom: 28px;
}

.title.sub {
  font-size: 20px;
}
@media (min-width: 599px) {
  .title.sub {
    margin: 33px 0 28px 0;
    font-size: 28px;
  }
}

.signInFormErrorMessage {
  margin-bottom: 24px;
}

.formErrorMessage {
  color: #ed6347;
  font-weight: 500;
  text-align: left;
}
.formErrorMessage.withBottomMargin {
  margin-bottom: 20px;
}

.nameFieldWidth {
  width: 136px;
  max-width: 202px;
}
@media (min-width: 599px) {
  .nameFieldWidth {
    width: 202px;
  }
}

.fieldMB {
  content: " ";
  margin-bottom: 16px;
}
@media (min-width: 599px) {
  .fieldMB {
    margin-bottom: 24px;
  }
}

.MB40Px {
  margin-bottom: 40px;
}

.alignBaseline {
  display: flex;
  align-items: baseline;
}

.description {
  margin-bottom: 46px;
  font-size: 14px;
  color: #333147;
}

.formButton {
  margin: 32px 0 0;
}
@media (min-width: 599px) {
  .formButton {
    margin: 32px 0 0;
  }
}
.formButton.mb0 {
  margin-bottom: 0;
}

.formButton.signin {
  margin: 50px 0 0;
}

.formButton.confirm {
  margin-top: 139px;
}
@media (min-width: 599px) {
  .formButton.confirm {
    margin-top: 179px;
  }
}

.link {
  cursor: pointer;
  font-weight: bold;
}

.formFooter {
  background-color: #ecebf0;
  height: 66px;
  color: #041e42;
  border-top: 1px solid #dfe1df;
}

.colorLightGrey {
  color: #8b919e;
  margin-top: 1.5rem;
}

.mobileConnectButton {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25), 0 1px 6px 0 rgba(0, 0, 0, 0.05);
}

.username {
  font-weight: 600;
}

.verifyMessage {
  margin-bottom: 27px;
}

.resultMessage {
  margin-bottom: 32px;
}

.resendArea {
  margin-top: 9px;
  font-size: 14px;
  font-weight: bold;
  color: #959595;
  cursor: default;
}

.resendArea.activated {
  cursor: pointer;
}
