.create-user-container {
  display: flex;
  flex-direction: column;
  width: 576px;
  color: #302e45;
}

.join-the-group-container {
  display: flex;
  flex-direction: column;
  color: #302e45;
}

.description {
  font-size: 16px;
  line-height: 1.25;
  color: #302e45;
}

.brand-line {
  width: 32px;
  height: 3px;
  object-fit: contain;
  background-image: linear-gradient(to right, #fdb515, #ec008c);
}

.title-header {
  line-height: 1.45;
  margin-top: 17px;
  margin-bottom: 0px;
}

.user-avatar {
  width: 168px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #302e45;
  text-align: center;
}

.number-of-mores {
  width: 48px;
  height: 48px;
  background-color: #c7c4cc;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.members-container {
  margin-top: 36px;
  display: flex;
  justify-content: center;
}

.accept-decline-button-container {
  margin-top: 75px;
  display: flex;
  justify-content: center;
}
