.passwordControls {
  position: absolute;
  left: 0;
  z-index: 1;
}

.passwordForgotNavLink {
  cursor: pointer;
  padding-top: 13px;
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 599px) {
  .passwordForgotNavLink {
    padding-top: 9px;
  }
}
